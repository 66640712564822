import React, { useState } from 'react';
import { Divider } from 'primereact/divider';
import Permissions from '../../components/Permissions';
import ButtonsActions from '../../components/ButtonsActions';
import { UserService } from '../../service/UserService';

function UserPermissions({ id, role, permissions, hide, onAlert, setAbilities, setLoading }) {

    const dataService = new UserService();

    const [selectedPermissions, setSelectedPermissions] = useState(permissions);

    const sendData = async() => {
        setLoading(true);
        try {
            const data = {
                _ID: id,
                _PERMISSIONS: selectedPermissions 
            }
            await dataService.updatePermissions(data).then( response => {
                setLoading(false);
                hide();

                if (response._SUCCESS) {
                    setAbilities(response._ITEMS);
                    onAlert("success", response._MESSAGE);
                } else {
                    onAlert("warn", response._MESSAGE);
                }
            })
        } catch (error) {
            setLoading(false);
            onAlert(error.variant, error.data);
        }
    }

    return (
        <div className="pb-3">
            <h2 className="text-color">Add / Remove Permissions</h2>
            <Divider align="center" />
            <h4> Rol: { role } </h4>

            <div className="p-fluid formgrid grid mb-4">
                <div className="field col-12">
                    <Permissions 
                        selections={ setSelectedPermissions } 
                        _permissions={ permissions } 
                        _selected={ selectedPermissions }
                    />
                </div>
            </div>

            <ButtonsActions cancel={ () => hide() } submit={ () => sendData() } />
        </div>
    )
}

export default UserPermissions;