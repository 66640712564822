import React, { useState, useRef, useEffect } from "react";
import { InputText } from 'primereact/inputtext';
import { RolService } from "../../service/RolService";
import { Divider } from "primereact/divider";
import ButtonsActions from "../../components/ButtonsActions";
import Permissions from "../../components/Permissions";


function EditAdd({ title, items, onItems, addItemList, updateItemList, hide, onAlert, setLoading }) {

    const dataService = new RolService(); //Call Service

    const toast = useRef(null);
    const [rolName, setRolName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    useEffect( () => {
        setRolName(items._NAME);
        setSelectedPermissions(items._PERMISSIONS); 
    }, []);

    const clearData = () => {
        setRolName(null);
    }

    const sendData = async () => {
        setLoading(true);
        
        try {
            let route;
    
            if ( title === "Edit" ) {
                route = dataService.updateDate;
            } else {
                route = dataService.saveData;
            }

            const data = {
                _NAME : rolName,
                _PERMISSIONS : selectedPermissions
            };

            await route(data).then( data => {
                setLoading(false);
                if ( data._SUCCESS ) {
                    hide();
                    clearData();

                    if ( data._OPERATION === "add" ) {
                        addItemList(data._ITEMS);
                    } else {
                        onItems(data._ITEMS);
                        updateItemList(data._ITEMS);
                    }

                    onAlert("success", data._MESSAGE);

                } else {

                    onAlert("warn", data._MESSAGE);
                }
            });

        } catch (error) {
            setLoading(false);
            onAlert(error.variant, error.data);
        }

    }

    return (
        <div className="pb-3">
            <h2 className="text-color mt-0">Roles {title}</h2>
            <Divider align="center" />
            <div className="p-fluid formgrid grid">
                <div className="field col-12">
                    <label htmlFor="formats" className="font-semibold">Rol Name</label>
                    <InputText
                        required
                        name='rolName'
                        placeholder='Administrator'
                        value={ rolName || '' }
                        onChange={ event => setRolName(event.target.value) }
                    />
                </div>
            </div>

            <div className="p-fluid formgrid grid mb-4">
                <div className="field col-12">
                    <label htmlFor="formats" className="font-semibold">Permissions</label>
                    <Permissions 
                        selections={ setSelectedPermissions } 
                        _permissions={ items._PERMISSIONS } 
                        _selected={ selectedPermissions }
                    />
                </div>
            </div>

            <ButtonsActions cancel={ () => hide() } submit={ () => sendData() } />
        </div>
    )
}

export default EditAdd
