import React, { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import { stringToFirstUppercase } from "../core/utils";
import { SCOPES, SCOPE } from "../core/RolePemissions";
import { useProvideModule } from "../hooks/useModules";

function Permissions({ selections, _permissions = [], _selected }) {
    const [selectedPermissions, setSelectedPermissions] = useState(_selected);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const { 0: modules } = useProvideModule();

    useEffect(() => {
        setSelectedPermissions(_permissions);
    }, [_permissions]);

    const INVALID_MODULES_FOR_STATUS = ["IT Request", "roles", "medios", "modules", "medios", "formats", "social media", "accounts"];

    const handleSelectAll = (e) => {
        const checked = e.checked; // Estado del checkbox "Select All"
        setSelectAllChecked(checked);

        // Genera permisos
        const updatedPermissions = checked ? modules.flatMap((mod) => SCOPES.map((scope) => `${mod._MODULE}.${scope.key}`)) : [];

        // Filtrar permisos no válidos (status asociado a módulos específicos)
        const filteredPermissions = updatedPermissions.filter((permission) => {
            const [module, scope] = permission.split(".");
            return !(scope === "status" && INVALID_MODULES_FOR_STATUS.includes(module));
        });

        setSelectedPermissions(filteredPermissions);
        selections(filteredPermissions); // Envía solo permisos válidos
    };

    const handlePermissionChange = (e) => {
        const updatedPermissions = e.checked
            ? [...selectedPermissions, e.value] // Agregar permiso
            : selectedPermissions.filter((item) => item !== e.value); // Eliminar permiso
    
        // Filtrar permisos no válidos
        const filteredPermissions = updatedPermissions.filter((permission) => {
            const [module, scope] = permission.split(".");
            return !(scope === "status" && INVALID_MODULES_FOR_STATUS.includes(module));
        });
    
        setSelectedPermissions(filteredPermissions);
        selections(filteredPermissions); // Envía solo permisos válidos
    };
    
    const renderModulePermissions = (module, scopesList) => (
        <div className="col-6 mb-3" key={`${module._MODULE}-${scopesList[0]?.key}`}>
            <Panel header={stringToFirstUppercase(module._MODULE)}>
                {scopesList.map((scope, i) => {
                    const permissionKey = `${module._MODULE}.${scope.key}`;
                    return (
                        <div className="field-checkbox" key={`${permissionKey}-${i}`}>
                            <Checkbox inputId={permissionKey} name={scope.key} value={permissionKey} onChange={handlePermissionChange} checked={selectedPermissions.includes(permissionKey)} />
                            <label htmlFor={permissionKey}>{stringToFirstUppercase(scope.key)}</label>
                        </div>
                    );
                })}
            </Panel>
        </div>
    );

    const filteredModules = modules.filter((mod) => ["medios", "formats", "social media", "accounts", "modules", "roles", "IT Request"].includes(mod._MODULE));

    return (
        <div>
            {/* Select All Checkbox */}
            <div className="p-fluid formgrid grid mt-3">
                <div className="col-6">
                    <div className="field-checkbox">
                        <Checkbox inputId="selectAll" name="selectAll" value={selectAllChecked} onChange={handleSelectAll} checked={selectAllChecked} />
                        <label htmlFor="selectAll" className="font-semibold">
                            Select All
                        </label>
                    </div>
                </div>
            </div>

            {/* Module Permissions */}
            <div className="p-fluid formgrid grid mt-3">
                {modules.map((module) => {
                    if (filteredModules.some((f) => f._MODULE === module._MODULE)) {
                        return renderModulePermissions(module, SCOPE);
                    }
                    return renderModulePermissions(module, SCOPES);
                })}
            </div>
        </div>
    );
}

export default Permissions;
